import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import OrderApi from "api/order.api";
import BottlesLocal from "features/Cart/BottlesLocal";

const initialState = {
  isLoading: false,
  orders: [],
  dram: [],
  bottle: [],
  others: {
    lengthDram: 0,
    lengthAuction: 0,
    lengthBottle: 0,
    lengthCask: 0,
    total: 0,
    length: 0,
  },
  listCountProduct: [],
  locations: [],
  error: null,
};

export const BidProductAsync = createAsyncThunk(
  "order/bidProduct",
  async (payload, thunkAPI) => {
    let dram = {};
    await OrderApi.bidProduct(
      payload.product_id,
      payload.user_id,
      payload.auction_id,
      payload.email
    )
      .then((res) => {
        dram = res;
      })
      .catch((err) => {
        dram = err.response.data;
      });
    return dram;
  }
);

export const GetOrdersByUserAsync = createAsyncThunk(
  "order/getOrdersByUser",
  async (payload, thunkAPI) => {
    let data = {};
    await OrderApi.getOrdersByUser(payload)
      .then((res) => {
        data = res;
      })
      .catch((err) => {
        data = err.response.data;
      });
    return data;
  }
);

export const GetHistoryByUserAsync = createAsyncThunk(
  "order/getHistoryByUser",
  async (payload, thunkAPI) => {
    let data = {};
    await OrderApi.getHistoryByUser(payload)
      .then((res) => {
        data = res;
      })
      .catch((err) => {
        data = err.response.data;
      });
    return data;
  }
);

export const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },

    // has error
    setError: (state, action) => {
      state.error = action.payload;
    },

    // get orders by user success
    getOrdersByUserSuccess: (state, action) => {
      state.orders = action.payload;
      state.isLoading = false;
      state.error = null;
    },

    setDram: (state, action) => {
      state.dram = action.payload;
    },

    setBottle: (state, action) => {
      state.bottle = action.payload;
    },

    setLengthDram: (state, action) => {
      state.others.lengthDram = action.payload;
    },

    setLengthBottle: (state, action) => {
      state.others.lengthBottle = action.payload;
    },

    setLengthAuction: (state, action) => {
      state.others.lengthAuction = action.payload;
    },

    setLengthCask: (state, action) => {
      state.others.lengthCask = action.payload;
    },

    setLength: (state, action) => {
      state.others.length = action.payload;
    },

    setTotal: (state, action) => {
      state.others.total = action.payload;
    },

    setLocations: (state, action) => {
      state.locations = action.payload;
    },

    setListCountProduct: (state, action) => {
      state.listCountProduct = action.payload;
    },
  },
  extraReducers: {
    [BidProductAsync.pending]: (state, action) => {
      state.isloading = true;
    },
    [BidProductAsync.fulfilled]: (state, action) => {
      state.isloading = false;
      state.dram = action.payload;
    },
    [BidProductAsync.rejected]: (state, action) => {
      state.isloading = false;
    },
    [GetOrdersByUserAsync.pending]: (state, action) => {
      state.isloading = true;
    },
    [GetOrdersByUserAsync.fulfilled]: (state, action) => {
      state.isloading = false;
      state.data = action.payload;
    },
    [GetOrdersByUserAsync.rejected]: (state, action) => {
      state.isloading = false;
    },
  },
});

export const { bidProduct } = orderSlice.actions;
export const selectOrder = (state) => state.order.data;
export const selectDram = (state) => state.order.dram;
export default orderSlice.reducer;

const getCurrentTargetByAuction = async (auction_id, product_id) => {
  try {
    const res = await OrderApi.getCountOrdersByAuction(auction_id);
    let count = 0;
    res.order.forEach((item) => {
      if (item.product === product_id) {
        count = item.quantity;
      }
    });
    return count;
  } catch (error) {
    return 0;
  }
};

export function GetOrdersByUser(id) {
  return async (dispatch) => {
    try {
      dispatch(orderSlice.actions.setIsLoading(true));
      const res = await OrderApi.getOrdersByUser(id);
      // const dram = res.order.filter((item) => item.type === "Dram");
      const bottle = res.order.filter((item) => item.type === "Bottle");
      const dram = res.order.filter((item) => item.type === "Dram");
      const locations = res.locations || [];

      let total = 0;
      let lengthDram = 0;
      let lengthBottle = 0;
      let lengthAuction = 0;
      let drams = [];

      dram.map((items) => {
        let oItems = [];
        let { orderItems, ...rest } = items;

        orderItems.map((item) => {
          if (!item?.dram?.current_price) return null;
          if (item.paymentStatus !== "unpaid") return null;
          oItems.push(item);
          return null;
        });

        if (oItems.length > 0) {
          drams.push({ ...rest, orderItems: oItems });
        }
        return null;
      });

      const calculateOrderTotal = (items) => {
        let orderTotal = 0;
        items.orderItems.forEach((item) => {
          const isSale = () => {
            if (!item) return false;
            if (item.product.prod_price_sale) {
              if (item.product.prod_price_sale < item.product.prod_price) {
                return true;
              }
            }
            return false;
          };
          if (item.paymentStatus !== "unpaid") return;

          if (items.type === "Dram") {
            if (!item?.dram?.current_price) {
              return;
            }
            if (item.product.prod_type !== "Dram Shop") {
              lengthBottle += item.quantity;
            } else {
              lengthDram += item.quantity;
            }
            lengthAuction += item.quantity;
            orderTotal += item?.dram?.current_price * item.quantity;
          } else {
            if (["Dram Shop"].includes(item.product.prod_type)) {
              lengthDram += item.quantity;
              if (isSale()) {
                orderTotal += item.product.prod_price_sale * item.quantity;
              } else {
                orderTotal += item.product.prod_price * item.quantity;
              }
            } else {
              lengthBottle += item.quantity;
              if (isSale()) {
                orderTotal += item.product.prod_price_sale * item.quantity;
              } else {
                orderTotal += item.product.prod_price * item.quantity;
              }
            }
          }
        });
        return orderTotal;
      };

      res.order.forEach((items) => {
        total += calculateOrderTotal(items);
      });

      console.log(drams);

      let length = lengthDram + lengthBottle;
      dispatch(orderSlice.actions.setDram(drams));
      dispatch(orderSlice.actions.setBottle(bottle));
      dispatch(orderSlice.actions.getOrdersByUserSuccess(res.order));
      dispatch(orderSlice.actions.setLengthDram(lengthDram));
      dispatch(orderSlice.actions.setLengthBottle(lengthBottle));
      dispatch(orderSlice.actions.setLengthAuction(lengthAuction));
      dispatch(orderSlice.actions.setLength(length));
      dispatch(orderSlice.actions.setTotal(total));
      dispatch(orderSlice.actions.setLocations(locations));
    } catch (error) {
      dispatch(orderSlice.actions.setError(error));
    }
  };
}

export function ChangeQuantityBottle(product, key, user) {
  return async (dispatch) => {
    try {
      dispatch(orderSlice.actions.setIsLoading(true));
      if (key === "add") {
        const res = await OrderApi.bidBottle(user?._id, product?._id);
        if (res.code === "success") {
          dispatch(GetOrdersByUser(user._id));
          return {
            code: "success",
            message: res.message,
          };
        } else {
          // dispatch(orderSlice.actions.setError(res));
          return {
            code: "error",
            message: res.message,
          };
        }
      } else if (key === "sub") {
        const res = await OrderApi.subBottle(user?._id, product?._id);
        if (res.code === "success") {
          dispatch(GetOrdersByUser(user._id));
          return {
            code: "success",
            message: res.message,
          };
        } else {
          return {
            code: "error",
            message: res.message,
          };
        }
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        return {
          code: "error",
          message: error.response.data.message,
        };
      } else {
        return {
          code: "error",
          message: error.message,
        };
      }
    }
  };
}

export function ChangeQuantityDram(item, key, auction, user) {
  return async (dispatch) => {
    const currentTarget = await getCurrentTargetByAuction(
      auction._id,
      item?.product?._id
    );

    try {
      dispatch(orderSlice.actions.setIsLoading(true));
      if (
        new Date().getTime() > new Date(auction?.auction_end_date).getTime()
      ) {
        return {
          code: "error",
          message: "Đấu giá đã kết thúc",
        };
      }
      if (currentTarget >= item?.dram?.dram_target) {
        return {
          code: "error",
          message: "Số lượng sản phẩm đã đạt mục tiêu",
        };
      }
      if (key === "add") {
        const res = await OrderApi.bidProduct(
          user?._id,
          item?.product?._id,
          auction?._id
        );
        if (res.code === "success") {
          dispatch(GetOrdersByUser(user._id));
          return {
            code: "success",
            message: "Đấu giá thành công",
          };
        } else {
          dispatch(orderSlice.actions.setError(res));
          return {
            code: "error",
            message: "Đấu giá thất bại",
          };
        }
      } else if (key === "sub") {
        if (currentTarget === 0) return;
        const res = await OrderApi.subProduct(
          user?._id,
          item?.product?._id,
          auction?._id
        );
        if (res.code === "success") {
          dispatch(GetOrdersByUser(user._id));
          return {
            code: "success",
            message: "Đấu giá thành công",
          };
        } else {
          dispatch(orderSlice.actions.setError(res));
          return {
            code: "error",
            message: "Đấu giá thất bại",
          };
        }
      }
    } catch (error) {
      return error;
    }
  };
}

export function BidProduct(user_id, product_id, auction_id, email) {
  return async (dispatch) => {
    try {
      dispatch(orderSlice.actions.setIsLoading(true));
      const res = await OrderApi.bidProduct(
        product_id,
        user_id,
        auction_id,
        email
      );
      if (res.code === "success") {
        dispatch(GetOrdersByUser(user_id));
        return {
          code: "success",
        };
      } else {
        if (res.code) {
          return {
            code: res.code,
            message: res.message,
          };
        }
      }
    } catch (error) {
      return {
        code: "error",
      };
    }
  };
}

export function removeProduct(user_id, product_id) {
  return async (dispatch) => {
    try {
      dispatch(orderSlice.actions.setIsLoading(true));
      const res = await OrderApi.removeProduct(user_id, product_id);
      if (res.code === "success") {
        dispatch(GetOrdersByUser(user_id));
        return {
          code: "success",
          message: "Xóa thành công",
        };
      } else {
        return {
          code: "error",
          message: "Xóa thất bại",
        };
      }
    } catch (error) {
      return {
        code: "error",
        message: "Xóa thất bại",
      };
    }
  };
}
