import { PATHS } from "routes/paths";

const navConfigMall = {
  items: [
    {
      name: "live_auction",
      children: [
        {
          name: "singapore_auction",
          url: PATHS.whiskyAuction.singapore,
          location: "Singapore",
        },
        {
          name: "malaysia_auction",
          url: PATHS.whiskyAuction.malaysia,
          location: "Malaysia",
        },
        {
          name: "vietnam_auction",
          url: PATHS.whiskyAuction.vietnam,
          location: "Vietnam",
        },
      ],
    },
    {
      name: "previous_auction",
      // url: PATHS.previousBids,
      children: [
        {
          name: "singapore_auction",
          url: PATHS.previousAuctions.singapore,
        },
        {
          name: "malaysia_auction",
          url: PATHS.previousAuctions.malaysia,
        },
        {
          name: "vietnam_auction",
          url: PATHS.previousAuctions.vietnam,
        },
      ],
    },
    {
      name: "register_lots",
      children: [
        {
          name: "submit_lots",
          url: PATHS.registerLots.submitLots,
        },
        {
          name: "request_valuation",
          url: PATHS.registerLots.requestValuation,
        },
        {
          name: "auction_schedule",
          url: PATHS.auctionSchedule,
        },
      ],
    },
    {
      name: "bottle_shop",
      // url: PATHS.bottleshop,
      children: [
        {
          name: "singapore_shop",
          url: PATHS.bottleshop.singapore,
        },
        // {
        //   name: "malaysia_shop",
        //   url: PATHS.bottleshop.malaysia,
        // },
        {
          name: "hongkong_shop",
          url: PATHS.bottleshop.hongKong,
        },
        {
          name: "vietnam_shop",
          url: PATHS.bottleshop.vietnam,
        },
      ],
    },
    {
      name: "dram_shop",
      url: PATHS.dram.root,
      children: [
        {
          name: "singapore_dram",
          url: PATHS.dram.root,
        },
      ],
    },

    // {
    //   name: "new_article",
    //   url: PATHS.newsAndArticles.root,
    // },
    {
      name: "cask_shop",
      url: PATHS.caskShop.root,
    },
  ],
};

export default navConfigMall;
