import useLang from "hooks/UseLang";
import React from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { Link, useLocation } from "react-router-dom";
import { PATHS } from "routes/paths";

function HeaderPC({
  menuOpen,
  setMenuOpen,
  classes,
  navConfig,
  navConfigTwo,
  logo,
  t,
  handleCheckActive,
  isTablet,
  lives,
  check,
  setSearch,
  setcheckAuctionActiveSub,
  setcheckPreviousAuctionActiveSub,
  setRegisterLotsActiveSub,
  checkAuctionActiveSub,
  checkPreviousAuctionActiveSub,
  checkRegisterLotsActiveSub,
  menuToggleHandler,
}) {
  const location = useLocation();
  const { isCN } = useLang();
  return (
    <header className={`${classes.header} header`}>
      <div className={classes.header__content}>
        <div className={classes.header__content__toggle}>
          {!menuOpen ? (
            <AiOutlineMenu
              onClick={menuToggleHandler}
              className={classes.header__content__toggle__menu}
            />
          ) : (
            <AiOutlineClose
              onClick={menuToggleHandler}
              className={classes.header__content__toggle__close}
            />
          )}
        </div>
        <div className="header_middle_content">
          <div className="header_middle_content_bottom">
            <nav
              style={{
                display: menuOpen ? "flex" : "",
              }}
              className={`${classes.header__content__nav}`}
            >
              <ul className="menu_item">
                {navConfig.items.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      {item.name === "live_auction" ? (
                        <li className="dropdown">
                          <Link
                            style={{
                              color:
                                checkAuctionActiveSub === true && "#ff9e00",

                              fontWeight: isCN ? "bold" : "normal",
                            }}
                          >
                            {t(`header.${item.name}`)}
                          </Link>
                          <ul className="dropdown-content">
                            {item.children.map((itemchildren, index) => {
                              const isLive = lives.includes(
                                itemchildren.location
                              );
                              return (
                                <li
                                  key={index}
                                  className={`${
                                    isLive
                                      ? "live"
                                      : handleCheckActive(itemchildren.url)
                                  }`}
                                  onClick={() => setMenuOpen(false)}
                                >
                                  <Link
                                    onClick={() => {
                                      if (
                                        handleCheckActive(itemchildren.url) ===
                                        "active"
                                      ) {
                                        if (
                                          [
                                            PATHS.whiskyAuction.root,
                                            PATHS.whiskyAuction.singapore,
                                            PATHS.whiskyAuction.malaysia,
                                            PATHS.whiskyAuction.vietnam,
                                          ].includes(location.pathname)
                                        ) {
                                          window.location.href =
                                            itemchildren.url;
                                        }
                                      }
                                      setSearch("");
                                      setcheckAuctionActiveSub(true);
                                      setcheckPreviousAuctionActiveSub(false);
                                      setRegisterLotsActiveSub(false);
                                    }}
                                    to={itemchildren.url}
                                  >
                                    {t(`header.${itemchildren.name}`)}
                                    {isLive && (
                                      <p className="live-text">
                                        {t(`header.auction_live`)}
                                      </p>
                                    )}
                                  </Link>
                                </li>
                              );
                            })}
                          </ul>
                        </li>
                      ) : (
                        <>
                          {item?.name === "previous_auction" ? (
                            <>
                              <li className="dropdown">
                                <Link
                                  style={{
                                    color:
                                      checkPreviousAuctionActiveSub === true &&
                                      "#ff9e00",
                                    fontWeight: isCN ? "bold" : "normal",
                                  }}
                                >
                                  {t(`header.${item.name}`)}
                                </Link>
                                <ul className="dropdown-content">
                                  {item.children.map((itemchildren, index) => {
                                    return (
                                      <li
                                        key={index}
                                        className={handleCheckActive(
                                          itemchildren.url
                                        )}
                                        onClick={() => setMenuOpen(false)}
                                      >
                                        <Link
                                          onClick={() => {
                                            if (
                                              handleCheckActive(
                                                itemchildren.url
                                              ) === "active"
                                            ) {
                                              // if (
                                              //   item.url ===
                                              //   `/previous-auctions/${check}`
                                              // ) {
                                              //   window.location.href = item.url;
                                              // } else {
                                              // }
                                              if (
                                                [
                                                  PATHS.previousAuctions.root,
                                                  PATHS.previousAuctions
                                                    .singapore,
                                                  PATHS.previousAuctions
                                                    .malaysia,
                                                  PATHS.previousAuctions
                                                    .vietnam,
                                                ].includes(location.pathname)
                                              ) {
                                                window.location.href =
                                                  itemchildren.url;
                                              }
                                            }
                                            setSearch("");
                                            setcheckPreviousAuctionActiveSub(
                                              true
                                            );
                                            setRegisterLotsActiveSub(false);

                                            setcheckAuctionActiveSub(false);
                                          }}
                                          to={itemchildren.url}
                                        >
                                          {t(`header.${itemchildren.name}`)}
                                        </Link>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </li>
                            </>
                          ) : (
                            <>
                              {item?.name === "register_lots" && (
                                <>
                                  <li className="dropdown">
                                    <Link
                                      style={{
                                        color:
                                          checkRegisterLotsActiveSub === true &&
                                          "#ff9e00",
                                      }}
                                    >
                                      {t(`header.${item.name}`)}
                                    </Link>
                                    <ul className="dropdown-content">
                                      {item.children.map(
                                        (itemchildren, index) => {
                                          return (
                                            <li
                                              key={index}
                                              className={handleCheckActive(
                                                itemchildren.url
                                              )}
                                              onClick={() => setMenuOpen(false)}
                                            >
                                              <Link
                                                onClick={() => {
                                                  if (
                                                    handleCheckActive(
                                                      itemchildren.url
                                                    ) === "active"
                                                  ) {
                                                    if (
                                                      item.url ===
                                                      `/previous-auctions/${check}`
                                                    ) {
                                                      window.location.href =
                                                        item.url;
                                                    } else {
                                                      window.location.reload();
                                                    }
                                                  }
                                                  setSearch("");
                                                  setRegisterLotsActiveSub(
                                                    true
                                                  );
                                                  setcheckPreviousAuctionActiveSub(
                                                    false
                                                  );
                                                  setcheckAuctionActiveSub(
                                                    false
                                                  );
                                                }}
                                                to={itemchildren.url}
                                              >
                                                {t(
                                                  `header.${itemchildren.name}`
                                                )}
                                              </Link>
                                            </li>
                                          );
                                        }
                                      )}
                                    </ul>
                                  </li>
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </React.Fragment>
                  );
                })}

                {isTablet && (
                  <>
                    {navConfigTwo.items.map((item, index) => {
                      return (
                        <React.Fragment key={index}>
                          {item.name === "bottle_shop" ? (
                            <li className="dropdown">
                              <Link>{t(`header.${item.name}`)}</Link>
                              <ul className="dropdown-content">
                                {item.children.map((itemchildren, index) => {
                                  return (
                                    <li
                                      key={index}
                                      className={handleCheckActive(
                                        itemchildren.url
                                      )}
                                      onClick={() => setMenuOpen(false)}
                                    >
                                      <Link
                                        onClick={() => {
                                          if (
                                            handleCheckActive(
                                              itemchildren.url
                                            ) === "active"
                                          ) {
                                            // window.location.reload();
                                            if (
                                              [
                                                PATHS.bottleshop.root,
                                                PATHS.bottleshop.singapore,
                                                PATHS.bottleshop.malaysia,
                                                PATHS.bottleshop.vietnam,
                                              ].includes(location.pathname)
                                            ) {
                                              window.location.href =
                                                itemchildren.url;
                                            }
                                          }
                                          setSearch("");
                                          setcheckAuctionActiveSub(false);
                                          setcheckPreviousAuctionActiveSub(
                                            false
                                          );
                                          setRegisterLotsActiveSub(false);
                                        }}
                                        to={itemchildren.url}
                                      >
                                        {t(`header.${itemchildren.name}`)}
                                      </Link>
                                    </li>
                                  );
                                })}
                              </ul>
                            </li>
                          ) : (
                            <li
                              className={handleCheckActive(item.url)}
                              onClick={() => setMenuOpen(false)}
                            >
                              <Link
                                to={item.url}
                                onClick={() => {
                                  if (
                                    handleCheckActive(item.url) === "active"
                                  ) {
                                    if (item.url === "/sg/news-and-articles") {
                                      window.location.href = item.url;
                                    } else {
                                      window.location.reload();
                                    }
                                  }
                                  setSearch("");
                                  setcheckAuctionActiveSub(false);
                                  setcheckPreviousAuctionActiveSub(false);
                                }}
                              >
                                {t(`header.${item.name}`)}
                              </Link>
                            </li>
                          )}
                        </React.Fragment>
                      );
                    })}
                  </>
                )}
              </ul>
            </nav>
          </div>
        </div>
        <Link
          className={classes.header__content__logo}
          // to={PATHS.root}
          onClick={() => {
            // setMenuOpen(false);
            // setSearch("");
            // setcheckAuctionActiveSub(false);
            // setcheckPreviousAuctionActiveSub(false);
            // setRegisterLotsActiveSub(false);
            window.location.href = PATHS.root;
          }}
        >
          <img src={logo} alt="logo" />
        </Link>
        {!isTablet && (
          <div className="header_middle_content">
            <div className="header_middle_content_bottom">
              <nav
                style={{ display: menuOpen ? "flex" : "" }}
                className={`${classes.header__content__nav__two}`}
              >
                <ul className="menu_item">
                  {navConfigTwo.items.map((item, index) => {
                    return (
                      <React.Fragment key={index}>
                        {item.children ? (
                          <li className="dropdown">
                            <Link
                              style={{
                                fontWeight: isCN ? "bold" : "normal",
                              }}
                            >
                              {t(`header.${item.name}`)}
                            </Link>
                            <ul className="dropdown-content">
                              {item.children.map((itemchildren, index) => {
                                return (
                                  <li
                                    key={index}
                                    className={handleCheckActive(
                                      itemchildren.url
                                    )}
                                    onClick={() => setMenuOpen(false)}
                                  >
                                    <Link
                                      onClick={() => {
                                        if (
                                          handleCheckActive(
                                            itemchildren.url
                                          ) === "active"
                                        ) {
                                          // window.location.reload();
                                          if (
                                            [
                                              PATHS.bottleshop.root,
                                              PATHS.bottleshop.singapore,
                                              PATHS.bottleshop.malaysia,
                                              PATHS.bottleshop.vietnam,
                                              PATHS.bottleshop.hongKong,
                                              PATHS.dram.root,
                                              PATHS.caskShop.root,
                                            ].includes(location.pathname)
                                          ) {
                                            window.location.href =
                                              itemchildren.url;
                                          }
                                        }
                                        setSearch("");
                                        setcheckAuctionActiveSub(false);
                                        setcheckPreviousAuctionActiveSub(false);
                                        setRegisterLotsActiveSub(false);
                                      }}
                                      to={itemchildren.url}
                                    >
                                      {t(`header.${itemchildren.name}`)}
                                    </Link>
                                  </li>
                                );
                              })}
                            </ul>
                          </li>
                        ) : (
                          <li
                            className={handleCheckActive(item.url)}
                            onClick={() => setMenuOpen(false)}
                          >
                            <Link
                              to={item.url}
                              onClick={() => {
                                if (handleCheckActive(item.url) === "active") {
                                  if (item.url === "/sg/news-and-articles") {
                                    window.location.href = item.url;
                                  } else {
                                    window.location.href = item.url;
                                  }
                                }
                                setSearch("");
                                setcheckAuctionActiveSub(false);
                                setcheckPreviousAuctionActiveSub(false);
                              }}
                              style={{
                                fontWeight: isCN ? "bold" : "normal",
                              }}
                            >
                              {t(`header.${item.name}`)}
                            </Link>
                          </li>
                        )}
                      </React.Fragment>
                    );
                  })}
                </ul>
              </nav>
            </div>
          </div>
        )}
      </div>
    </header>
  );
}

export default HeaderPC;
