/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useEffect, useLayoutEffect, useState } from "react";

import { useLocation, useParams } from "react-router-dom";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import "./index.scss";
import classes from "./Header.module.scss";
import { deleteCookie } from "config/cookies";
import logo from "assets/images/logos/logo.png";
import { useDispatch, useSelector } from "react-redux";
import ProductSearch from "./ProductSearch";
import navConfig from "../NavConfig";
import navConfigTwo from "../NavConfigTwo";
import { PATHS } from "routes/paths";
import HeaderMobilePhone from "./components/HeaderMobilePhone";
import { getHomeContent } from "redux/modules/setting/settingcontent";
import useViewport from "hooks/ViewPort";
// import SearchHeader from "./components/SearchHeader";
import HeaderPC from "./components/HeaderPC";
import { fetchLives } from "redux/modules/auction";

// const ProductSearch = React.lazy(() => import("./ProductSearch"));
// const HeaderMobilePhone = React.lazy(() =>
//   import("./components/HeaderMobilePhone")
// );
// const HeaderPC = React.lazy(() => import("./components/HeaderPC"));
const SearchHeader = React.lazy(() => import("./components/SearchHeader"));

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [lang, setLang] = useState();
  const [menuActive, setMenuActive] = useState("home");
  const [checkAuctionActiveSub, setcheckAuctionActiveSub] = useState(false);
  const [checkPreviousAuctionActiveSub, setcheckPreviousAuctionActiveSub] =
    useState(false);
  const [, setIsFocus] = useState(false);
  const [checkRegisterLotsActiveSub, setRegisterLotsActiveSub] =
    useState(false);
  const [search, setSearch] = useState("");
  const { others } = useSelector((state) => state.order);
  const { others: cartsOther } = useSelector((state) => state.cartlocal);
  const homeContent = useSelector((state) => state.settingcontent.homeContent);
  const [selectSearch, setSelectSearch] = useState("all");
  // const [lives, setLives] = useState([]);
  const { lives } = useSelector((state) => state.auction);
  const check = useParams();
  const dispatch = useDispatch();
  const location = useLocation();

  useLayoutEffect(() => {
    if (homeContent) return;
    dispatch(getHomeContent());
  }, [dispatch, homeContent]);

  useLayoutEffect(() => {
    setMenuActive(
      "/" +
        location.pathname.split("/")[1] +
        "/" +
        location.pathname.split("/")[2]
    );
    if (menuOpen) {
      setMenuOpen(false);
    }
  }, [location.pathname, menuOpen]);

  const { pathname } = useLocation();

  const [isHome, setIsHome] = useState(false);

  useLayoutEffect(() => {
    if (pathname === "/" || pathname.split("/")[2] === "product") {
      setIsHome(true);
    } else {
      setIsHome(false);
    }
  }, [pathname]);

  useEffect(() => {
    if (pathname === "/whisky-auction-live/singapore") {
      setSelectSearch("live Singapore");
    } else if (pathname === "/whisky-auction-live/malaysia") {
      setSelectSearch("live Malaysia");
    } else if (pathname === "/whisky-auction-live/vietnam") {
      setSelectSearch("live Vietnam");
    } else if (pathname.includes("/whisky-auctions-previous")) {
      const previous = pathname.split("/");
      if (previous.length < 5) {
        if (pathname.includes("/malaysia")) {
          setSelectSearch("previous Malaysia");
        } else if (pathname.includes("/vietnam")) {
          setSelectSearch("previous Vietnam");
        } else {
          setSelectSearch("previous Singapore");
        }
      }
    } else if (pathname === "/whisky-shop/singapore") {
      setSelectSearch("Bottle Singapore");
    } else if (pathname === "/whisky-shop/malaysia") {
      setSelectSearch("Bottle Malaysia");
    } else if (pathname === "/whisky-shop/hong-kong") {
      setSelectSearch("Bottle Hong Kong");
    } else if (pathname === "/whisky-shop/vietnam") {
      setSelectSearch("Bottle Vietnam");
    } else if (pathname === "/whisky-dram") {
      setSelectSearch("Dram Singapore");
    } else if (pathname === "/whisky-cask") {
      setSelectSearch("Cask Singapore");
    } else {
      const splits = pathname.split("/");
      if (splits.length < 4) {
        setSelectSearch("all");
      }
    }
  }, [pathname]);

  // const fetchLives = useCallback(async () => {
  //   try {
  //     const res = await axiosClient.get("/auction/live/location");
  //     if (res) {
  //       setLives(res);
  //     }
  //   } catch (error) {}
  // }, []);

  useLayoutEffect(() => {
    // fetchLives();
    dispatch(fetchLives());
  }, [dispatch]);

  const { t, i18n } = useTranslation();

  const menuToggleHandler = () => {
    setMenuOpen(!menuOpen);
  };

  useLayoutEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  useLayoutEffect(() => {
    i18next.changeLanguage(lang);
    localStorage.setItem("lang", lang);
  }, [lang]);

  const handleCheckActive = (path) => {
    if (path === menuActive) {
      return "active";
    }
    return "";
  };

  const viewPort = useViewport();
  const isMobile = viewPort.width <= 1050;
  const isTablet = viewPort.width <= 1050;
  return (
    <div>
      {isMobile ? (
        <>
          <HeaderMobilePhone
            isMobile={isMobile}
            isHome={isHome}
            detailContent1={homeContent?.header_header_content}
            detailContent2={homeContent?.header_header_content_full}
            checkHome={true}
            search={search}
            setSearch={setSearch}
            others={others}
            cartsOther={cartsOther}
            menuActive={menuActive}
            setMenuActive={setMenuActive}
            lang={lang}
            setLang={setLang}
            menuOpen={menuOpen}
            setMenuOpen={setMenuOpen}
            viewPort={viewPort}
            lives={lives}
            handleLogout={() => {
              deleteCookie("user");
              // setUser(null);
              window.location.reload();
            }}
          />
        </>
      ) : (
        <>
          <HeaderPC
            menuOpen={menuOpen}
            setMenuOpen={setMenuOpen}
            classes={classes}
            navConfig={navConfig}
            navConfigTwo={navConfigTwo}
            logo={logo}
            t={t}
            handleCheckActive={handleCheckActive}
            isTablet={isTablet}
            lives={lives}
            check={check}
            setSearch={setSearch}
            setcheckAuctionActiveSub={setcheckAuctionActiveSub}
            setcheckPreviousAuctionActiveSub={setcheckPreviousAuctionActiveSub}
            setRegisterLotsActiveSub={setRegisterLotsActiveSub}
            checkAuctionActiveSub={checkAuctionActiveSub}
            checkPreviousAuctionActiveSub={checkPreviousAuctionActiveSub}
            checkRegisterLotsActiveSub={checkRegisterLotsActiveSub}
            menuToggleHandler={menuToggleHandler}
          />
        </>
      )}
      {!location.pathname.includes("auth") && (
        <SearchHeader
          search={search}
          selectSearch={selectSearch}
          setSelectSearch={setSelectSearch}
          setIsFocus={setIsFocus}
          setSearch={setSearch}
        />
      )}

      {search !== "" && (
        <ProductSearch search={search} selectSearch={selectSearch} />
      )}
    </div>
  );
};

export default Header;
