// import AreYouOver from "components/AreYouOver";
// import FooterHome from "features/Home/components/FooterHome";
import React, { Suspense, useState } from "react";
import { Outlet } from "react-router-dom";
// import Footer from "./Footer";
// import Header from "./Header";
import "./index.scss";
import LoadingLazy from "components/LoadingLazy";
import Noel from "./components/Noel";
const FooterHome = React.lazy(() =>
  import("features/Home/components/FooterHome")
);
const AreYouOver = React.lazy(() => import("components/AreYouOver"));
const Header = React.lazy(() => import("./Header"));

export default function SingaporeLayout() {
  const [isOver, setIsOver] = useState(localStorage.getItem("isOver"));
  return (
    <Suspense fallback={<LoadingLazy />}>
      {/* <Noel /> */}
      <div id="layout-singapore">
        {isOver !== "true" && <AreYouOver setIsOver={setIsOver} />}
        <Header />
        <Outlet />
        <FooterHome />
        {/* {!isHome && <FooterHome />} */}
      </div>
    </Suspense>
  );
}
