import { PATHS } from "routes/paths";

const navConfigTwo = {
  items: [
    {
      name: "bottle_shop",
      // url: PATHS.bottleshop,
      children: [
        {
          name: "singapore_shop",
          url: PATHS.bottleshop.singapore,
        },
        // {
        //   name: "malaysia_shop",
        //   url: PATHS.bottleshop.malaysia,
        // },
        {
          name: "hongkong_shop",
          url: PATHS.bottleshop.hongKong,
        },
        {
          name: "vietnam_shop",
          url: PATHS.bottleshop.vietnam,
        },
      ],
    },
    {
      name: "dram_shop",
      url: PATHS.dram.root,
      children: [
        {
          name: "singapore_dram",
          url: PATHS.dram.root,
        },
      ],
    },

    // {
    //   name: "new_article",
    //   url: PATHS.newsAndArticles.root,
    // },
    {
      name: "cask_shop",
      url: PATHS.caskShop.root,
    },
  ],
};

export default navConfigTwo;
