function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "/auth";
const ROOTS_MY_ACCOUNT = "/my-account";
const ROOTS = "";
const ROOTS_VN = "/vn";

export const PATHS_AUTH = {
  root: ROOTS_AUTH,
  LOGIN: path(ROOTS_AUTH, "/login"),
  REGISTER: path(ROOTS_AUTH, "/register"),
  FORGOT_PASSWORD: path(ROOTS_AUTH, "/forgot-password"),
  RESET_PASSWORD: path(ROOTS_AUTH, "/reset-password/:id/:token"),
  verifyEmail: path(ROOTS_AUTH, "/verify/:token"),
};

export const PATHS_MY_ACCOUNT = {
  root: ROOTS_MY_ACCOUNT,
  profile: path(ROOTS_MY_ACCOUNT, "/profile"),
  cropprofile: path(ROOTS_MY_ACCOUNT, "/profile/change"),
  changePassword: path(ROOTS_MY_ACCOUNT, "/change-password"),
  address: path(ROOTS_MY_ACCOUNT, "/address"),
  addressAdd: path(ROOTS_MY_ACCOUNT, "/address/add"),
  addressEdit: path(ROOTS_MY_ACCOUNT, "/address/edit/:id"),
  order: path(ROOTS_MY_ACCOUNT, "/order"),
  orderDetail: path(ROOTS_MY_ACCOUNT, "/order/:id"),
  wishlist: path(ROOTS_MY_ACCOUNT, "/wishlist"),
  review: path(ROOTS_MY_ACCOUNT, "/review"),
  reviewAdd: path(ROOTS_MY_ACCOUNT, "/review/add"),
  reviewEdit: path(ROOTS_MY_ACCOUNT, "/review/edit/:id"),
  notification: path(ROOTS_MY_ACCOUNT, "/notification"),
  wallet: path(ROOTS_MY_ACCOUNT, "/wallet"),
  sellerWallet: path(ROOTS_MY_ACCOUNT, "/wallet/seller"),
  reward: path(ROOTS_MY_ACCOUNT, "/wallet/reward"),
  mybids: path(ROOTS_MY_ACCOUNT, "/my-bids"),
  mysales: path(ROOTS_MY_ACCOUNT, "/my-sales"),
  mywishlist: path(ROOTS_MY_ACCOUNT, "/my-wish-list"),
  orderHistory: path(ROOTS_MY_ACCOUNT, "/orders-history"),
  reviewHistory: path(ROOTS_MY_ACCOUNT, "/reviews-history"),
  articleHistory: path(ROOTS_MY_ACCOUNT, "/articles-history"),
  settings: path(ROOTS_MY_ACCOUNT, "/settings"),
};

export const PATH_PAGE = {
  commingSoon: path(ROOTS, "/comming-soon"),
  page404: path(ROOTS, "/404"),
  page500: path(ROOTS, "/500"),
  pageMaintenance: path(ROOTS, "/maintenance"),
};

export const PATHS = {
  root: ROOTS,
  home: path(ROOTS, "/home"),
  about: path(ROOTS, "/about-us-rare-rare-whisky-auction"),
  contact: path(ROOTS, "/contact"),
  auctionPreview: path(ROOTS, "/auction/preview/:id"),
  deliveryCalculator: path(ROOTS, "/delivery-calculator"),
  whiskyAuction: {
    root: path(ROOTS, "/whisky-auction-live"),
    product: path(ROOTS, "/whisky-auction-live/:location/:id/:prod_name"),
    detail: path(ROOTS, "/whisky-auction-live/:location"),
    singapore: path(ROOTS, "/whisky-auction-live/singapore"),
    vietnam: path(ROOTS, "/whisky-auction-live/vietnam"),
    malaysia: path(ROOTS, "/whisky-auction-live/malaysia"),
  },
  caskShop: {
    root: path(ROOTS, "/whisky-cask"),
    detail: path(ROOTS, "/whisky-cask/:location"),
    singapore: path(ROOTS, "/whisky-cask/singapore"),
    vietnam: path(ROOTS, "/whisky-cask/vietnam"),
    malaysia: path(ROOTS, "/whisky-cask/malaysia"),
    product: path(ROOTS, "/whisky-cask/:id/:name"),
  },
  buy_whisky_cask: path(ROOTS, "/buy-whisky-cask"),
  auctionSchedule: path(ROOTS, "/auction-schedule-rare-whisky-auction"),
  previousAuctions: {
    root: path(ROOTS, "/whisky-auctions-previous"),
    product: path(ROOTS, "/whisky-auctions-previous/:location/:id/:prod_name"),
    detail: path(ROOTS, "/whisky-auctions-previous/:location"),
    detailProduct: path(ROOTS, "/whisky-auctions-previous/:location/:id"),
    singapore: path(ROOTS, "/whisky-auctions-previous/singapore"),
    singaporeDetail: path(ROOTS, "/whisky-auctions-previous/singapore/:id"),
    vietnam: path(ROOTS, "/whisky-auctions-previous/vietnam"),
    vietnamDetail: path(ROOTS, "/whisky-auctions-previous/vietnam/:id"),
    malaysia: path(ROOTS, "/whisky-auctions-previous/malaysia"),
    malaysiaDetail: path(ROOTS, "/whisky-auctions-previous/malaysia/:id"),
  },
  reviewAndRating: {
    root: path(ROOTS, "/reviews"),
    form: path(ROOTS, "/reviews/form"),
  },
  registerLots: {
    root: path(ROOTS, "/register-lots"),
    submitLots: path(ROOTS, "/register-lots/submit-lots-rare-whisky-auction"),
    requestValuation: path(
      ROOTS,
      "/register-lots/request-valuation-rare-whisky-auction"
    ),
    confirmValuation: path(ROOTS, "/request-valuation/:id/:bottleId/confirm"),
  },
  howToBid: path(ROOTS, "/how-to-bid-rare-whisky-auction"),
  howToSell: path(ROOTS, "/how-to-sell-rare-whisky-auction"),
  shipping: path(ROOTS, "/shipping-delivery-rare-whisky-auction"),
  fAQ: path(ROOTS, "/faq-rare-whisky-auction"),
  distilleries: path(ROOTS, "/whisky-distillery"),
  singleDistillery: path(ROOTS, "/whisky-distillery/:id"),
  termsAndConditions: path(ROOTS, "/terms-and-conditions-rare-whisky-auction"),
  glossary: path(ROOTS, "/whisky-glossary"),
  independenBottlers: path(ROOTS, "/whisky-independent-bottlers"),
  singleIndependentBottlers: path(ROOTS, "/whisky-independent-bottlers/:id"),
  partnerShips: path(ROOTS, "/whisky-partnerships"),
  membership: path(ROOTS, "/membership"),
  storage: path(ROOTS, "/storage"),
  newsAndArticles: {
    root: path(ROOTS, "/whisky-news"),
    submit: path(ROOTS, "/whisky-news/submit-new-article"),
    submitUpdate: path(ROOTS, "/whisky-news/submit-new-article/:id"),
    detail: path(ROOTS, "/whisky-news/:id"),
  },
  dramshop: path(ROOTS, "/whisky-dram"),
  dramshopProduct: path(ROOTS, "/whisky-dram/:location/:id/:name"),
  bottleshop: {
    root: path(ROOTS, "/whisky-shop"),
    detail: path(ROOTS, "/whisky-shop/:location"),
    detailCategory: path(ROOTS, "/whisky-shop/:location/:category_name"),
    singapore: path(ROOTS, "/whisky-shop/singapore"),
    vietnam: path(ROOTS, "/whisky-shop/vietnam"),
    malaysia: path(ROOTS, "/whisky-shop/malaysia"),
    hongKong: path(ROOTS, "/whisky-shop/hong-kong"),
    product: path(ROOTS, "/whisky-shop/:location/:id/:name"),
  },
  dramshopDistillery: path(ROOTS, "/dram-shop/:category_name"),
  bottleshopDistillery: path(ROOTS, "/bottle-shop/:category_name"),
  cart: {
    root: path(ROOTS, "/cart"),
    test: path(ROOTS, "/cart/test"),
    secret: path(ROOTS, "/cart/:secret"),
  },
  checkout: {
    root: path(ROOTS, "/checkout"),
    success: path(ROOTS, "/checkout/success"),
    fail: path(ROOTS, "/checkout/fail"),
  },
  product: {
    root: path(ROOTS, "/product"),
    detail: path(ROOTS, "/product/:id"),
    detailAuction: path(ROOTS, "/product/:auction_id/:id"),
    previousAuction: path(ROOTS, "/product/:auction_id/:id/previous-auction"),
  },
  dram: {
    root: path(ROOTS, "/whisky-dram"),
    singapore: path(ROOTS, "/whisky-dram/singapore"),
    // detaildram: path(ROOTS, "/dram-shop/:id"),
    // detailAuctionDram: path(ROOTS, "/dram/:auction_id/:id"),
    dramDistillery: path(ROOTS, "/whisky-dram/:category_name"),
    product: path(ROOTS, "/whisky-dram/:location/:id/:name"),
  },
  unsubscribe: path(ROOTS, "/unsubscribe"),
};

export const PATHS_DISTILLERY = {
  root: "/whisky-distillery",
  detail: "/whisky-distillery/:id",
};

export const PATHS_VN = {
  root: ROOTS_VN,
};
